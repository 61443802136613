/* App.css */
.App {
  text-align: center;
  background-color: #282c34; /* Fallback color */
  background-image: url('./images/northinteractive-background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Ensure it covers the full viewport height */
}

.App-header {
  /* If you want the header to be transparent and show the background image, remove the background-color */
  /* Otherwise, keep it if you want a solid color header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Add more styles as needed */
